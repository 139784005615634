#mainBg {
  background-image: url(../../images/bg.png);
  background-repeat: no-repeat;
  height: auto;
  background-size: cover;
}

#tvBg {
  background-image: url(../../images/bg-pane.png);
  background-repeat: no-repeat;
  height: auto;
  background-size: cover;
  padding-bottom: 2rem !important;
}
#bg-brand {
  background-image: url(../../images/bg-brand.png);
  background-repeat: no-repeat;
  height: auto;
  margin-top: 6rem !important;
  margin-bottom: 5rem !important;
}
#tv-mock {
  background-image: url(../../images/tv-mock.png);
}

#headline {
  width: 100%;
  max-width: 450px;
  margin-top: 12rem !important;
}
#library {
  width: 100%;
  margin-top: 4rem !important;
}
#antv {
  width: 100%;
  margin-top: 2rem !important;
}
.carousel-tv {
  max-height: 574px;
  max-width: 956px;
  margin-top: 4.5rem !important;
  margin-bottom: 3rem !important;
  margin: auto;
}
.mock1 {
  width: 100%;
  max-width: 753px;
  height: auto;
  margin-top: 10rem !important;
  margin-bottom: 2rem !important;
}
.logoIcon {
  width: 100%;
  max-width: 172px;
  height: auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.playstore {
  width: 100%;
  max-width: 235px;
  height: auto;
  margin-top: 2rem !important;
}
.slogan {
  width: 100%;
  max-width: 288px;
  height: auto;
  margin-top: 3rem !important;
  margin-left: 2rem !important;
}
.brand {
  margin-top: 8rem !important;
}
.features {
  margin-top: 5rem !important;
  padding-bottom: 8rem !important;
}
.brand-v {
  height: 800px;
}
.button-more {
  margin-top: 3rem !important;
}
.icon-coll {
  width: 100%;
  margin: auto;
  margin-bottom: 4rem !important;
}
.coll-playstore {
  margin-top: 10rem !important;
}

.line {
  margin-top: 3.5rem !important;
  margin-bottom: 5rem !important;
}
.text-center {
  text-align: center;
}

.scroll-wrap {
  max-width: 1440px;
  margin: auto;
}
.footer-wrap {
  max-width: 1440px;
  margin: auto;
  background-color: #101010;
}
.scroll {
  overflow-y: hidden;
  height: 300px;
  white-space: nowrap;
  overflow-x: scroll;
}

.item {
  display: inline-block;
}

@media only screen and (max-width: 1440px) {
  #mainBg {
    background-image: none;
  }
  #tvBg {
    background-image: none;
  }
  #headline {
    margin-top: 2rem !important;
  }
  #icon-coll {
    margin-top: 2rem !important;
  }
  #centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.contact-form {
  margin-top: 5%;
  width: 100%;
}
.footer-title {
  text-align: center;
}
.contact-form .form-control {
  border-radius: 1rem;
}
.contact-image {
  text-align: center;
}
.contact-image img {
  border-radius: 6rem;
  width: 6%;
  margin-top: -2%;
  transform: rotate(29deg);
}
.contact-form form {
  padding: 9%;
}
.contact-form form .row {
  margin-bottom: -7%;
}
.contact-form h3 {
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: #0062cc;
}
.contact-form .btnContact {
  width: 50%;
  border: none;
  border-radius: 1rem;
  padding: 1.5%;
  background: #dc3545;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.btnContactSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  color: #fff;
  background-color: #0062cc;
  border: none;
  cursor: pointer;
}

.card-0 {
  overflow-x: hidden !important;
  padding: auto 0px auto 0px !important;
  background-color: #101010 !important;
  color: #ffffff !important;
  border-radius: 0;
  margin: 0 !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right
    calc(70px + (120 - 70) * ((100vw - 320px) / (1600 - 320))) bottom !important;
}

h5 {
  font-size: calc(
    16px + (21 - 16) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

h6 {
  font-size: calc(
    13px + (15 - 13) * ((100vw - 360px) / (1600 - 360))
  ) !important;
  color: #bbb !important;
}

.fa {
  cursor: pointer;
  font-size: 21px;
  margin: 5px 10px 5px 10px !important;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

li {
  margin-top: 8px;
  margin-bottom: 8px;
}

input {
  padding: 0.3rem;
  border-radius: 0.5rem !important;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

li:first-child {
  font-size: 20px !important;
  font-weight: bold;
}

small {
  font-size: calc(
    12px + (14 - 12) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.social {
  position: relative;
  left: -10px;
}

.Subscribe {
  background-color: #ffffff !important;
  font-weight: bold;
}

small {
  color: #777 !important;
}

.btn {
  border-radius: 50px;
}

.card-1 {
  border-left: 3px solid green !important;
  border-radius: 0;
}

hr.colored {
  height: 8px;
  background: linear-gradient(
    to left,
    rgba(196, 222, 138, 1) 0%,
    rgba(196, 222, 138, 1) 12.5%,
    rgba(245, 253, 212, 1) 12.5%,
    rgba(245, 253, 212, 1) 25%,
    rgba(255, 208, 132, 1) 25%,
    rgba(255, 208, 132, 1) 37.5%,
    rgba(242, 122, 107, 1) 37.5%,
    rgba(242, 122, 107, 1) 50%,
    rgba(223, 157, 185, 1) 50%,
    rgba(223, 157, 185, 1) 62.5%,
    rgba(192, 156, 221, 1) 62.5%,
    rgba(192, 156, 221, 1) 75%,
    rgba(95, 156, 217, 1) 75%,
    rgba(95, 156, 217, 1) 87.5%,
    rgba(94, 190, 227, 1) 87.5%,
    rgba(94, 190, 227, 1) 87.5%,
    rgba(94, 190, 227, 1) 100%
  );
}

.fa {
  padding: calc(10px + (10 - 10) * ((100vw - 360px) / (1600 - 360))) !important;
  font-size: calc(
    15px + (20 - 15) * ((100vw - 360px) / (1600 - 360))
  ) !important;
  width: calc(30px + (38 - 30) * ((100vw - 360px) / (1600 - 360))) !important;
  text-align: center;
  text-decoration: none;
  border-radius: 50px !important;
  margin: 6px !important;
}

a {
  text-decoration: none !important;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa:hover {
  background-color: transparent !important;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-instagram {
  background: #3f729b;
  color: white;
}

.fa-linkedin {
  background: #0e76a8;
  color: white;
}

.form-row {
  position: relative;
  left: calc(30px + (50 - 30) * ((100vw - 320px) / (1600 - 320)));
}

@media screen and (max-width: 726px) {
  .form-row {
    position: relative;
    left: 0px !important;
  }
}

@media screen and (max-width: 1143px) {
  .card-0 {
    background: none;
  }
}
